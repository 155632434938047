:root {
    --black: #000;
    --white: #fff;

    --gray: #C3CBCD;
    --light-gray: #F4F5F4;
    --dark-gray: #D9D9D9;
    
    --gold: #AE966B;
    --yellow: #F0E093;
    --light-yellow: #FFFAE3;

    --purple: #533366;
    --dark-purple: #443565;
    --light-purple: #766699;
    
    --linear-gradient: linear-gradient(90deg, var(--purple) 0%, #452C7D 100%);

    background: var(--white);
}

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.koisbot-container {
    margin: 100px auto;
    position: relative;
    /* max-width: 460px; */
    width: 100%;
    height: 630px;
    border-radius: 8px;
    background: var(--white);
    filter: drop-shadow(0px 8px 23.1px rgba(0, 0, 0, 0.14));
}

/* arrow */
/* .koisbot-container:after{
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: var(--white);
    position: absolute;
    bottom: -8px;
    right: 35px;
    transform: rotate(45deg);
    z-index: -1;
} */

/* OPEN BUTTON */
.koisbot-button{
    position: absolute;
    right: 10px;
    bottom: -100px;
}
.koisbot-button:hover svg circle{ transition: all 250ms ease; }
.koisbot-button:hover svg circle{ fill: var(--black); }

/* TOP BAR */
.top-bar {
    padding: 16px 26px;
    display: grid;
    grid-template-columns: 1fr 16px;
    align-items: center;
    gap: 26px;
    background: var(--linear-gradient);
}
.top-bar__header{
    margin: 0;
    font-family: "Adobe Garamond Pro";
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3; 
    color: var(--white);
}
/* mobile */
@media (max-width: 529px) {
    .top-bar__header{
        font-size: 18px;
    }
}

.top-bar__close {
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.top-bar__close svg{ position: relative; top: 2px; }
.top-bar__close svg rect{ transition: all 250ms ease; }
.top-bar__close:hover svg rect{ fill: var(--gold); }
/* END TOP BAR */

/* ALERT */
.alert-container{
    padding: 14px 16px;

    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 16px;

    font-size: 12px;
    background: var(--light-yellow);
    border-top: 1px solid var(--yellow);
    border-bottom: 1px solid var(--yellow);
}

/* LOAD MORE BUTTON */
.load-more-container, .loaded-messages-break-container{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.load-more-button{
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    text-decoration: underline;
}

button.load-more-button:disabled{
    text-decoration: none;
    cursor: default;
    color: var(--gray);
}

.load-more-button:before, .loaded-messages-break:before{
    content:" ";
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    background: #E2E5E6;
    text-decoration: none;
  }

  .load-more-button:before{
    left: -140px;
    width: 120px;
  }

  .loaded-messages-break:before{
    left: -152px;
    width:136px;
  }

  .load-more-button:after, .loaded-messages-break:after{
    content:" ";
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    background: #E2E5E6;
    text-decoration: none;
  }

  .load-more-button:after{
    left: 130px;
    width: 120px;
  }

  .loaded-messages-break:after{
    left: 110px;
    width:132px;
  }

  /* LOAD MORE BREAK */
  .loaded-messages-break{
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    color: var(--gray);
    font-size: 12px;
  }

/* CHAT */
.chat-history {
    margin: 26px 26px 0 26px;
    height: 450px;
    overflow-y: scroll;
    font-family:'Open Sans', sans-serif;
    font-size: 14px;
}
.chat-container{
    max-height: 460px;
    height: auto;
}

/* MESSAGES */
.bot-message, .user-message{ max-width: fit-content; }
.user-message {
    margin: 30px 0 30px auto;
    padding: 12px 16px;

    border-radius: 6px 0px 6px 6px;
    background: var(--light-purple);
    color: var(--white);
}
.user-message--logged-in{ background: var(--gold); }

.bot-message-wrapper {
    margin: 30px 0;
    display: grid;
    grid-template-columns: 42px 1fr;
    gap: 12px;
}

.bad-response{
    margin-top: 4px;
    display: grid;
    grid-template-columns: 16px 1fr;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: var(--purple);
    text-decoration: none;
    transition: all 250ms ease;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.bad-response span{ transition: all 250ms ease; opacity: 0; }
.bad-response span.flagged{ opacity: 1; }
.bad-response:hover span{ opacity: 1; }

.bot-message__name{
    margin-bottom: 6px;
    font-weight: 600;
}
.bot-message__content{
    padding: 12px 16px;
    border-radius: 0px 6px 6px 6px;
    background: var(--light-gray);
}
.bot-message__content p{
    white-space: break-spaces;
}
.bot-message__content p .citation{
    font-size: 12px;
}

.bot-message__content a{
    color: var(--purple);
    text-decoration: underline;
}

/* BOT FETCHING */
.bot-message__fetching{
    padding: 12px 16px;
    border-radius: 0px 6px 6px 6px;
    background: var(--light-gray);
}
.dots{ overflow: visible; }
.dots circle { animation: wave 1.3s linear infinite; }
.dots circle:nth-child(2) { animation-delay: -1.1s; }
.dots circle:nth-child(3) { animation-delay: -0.9s; }
@keyframes wave {
	0%, 60%, 100% { transform: initial; }
	30% { transform: translateY(-6px); }
}

/* INPUT BAR */
.input-bar {
    display: flex;
    height: 66px;
    border-top: 1px solid var(--gray);
    background: var(--white);
}
.input-bar textarea{
    padding: 16px 0 16px 26px;
    border-radius: 0 0 8px 8px;
    flex: 1;
    border: none;
    border-left: 7px solid var(--white);
    transition: all 250ms ease;
    resize: none;
}
.input-bar textarea:focus{
    border-left-color: var(--purple);
    outline: 0;
}
.input-bar button{
    margin: auto 26px;
    padding: 0;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    border: none;
}
.input-bar button svg circle{ transition: all 250ms ease; }
.input-bar button:hover svg circle{ fill: var(--purple); }
.input-bar button:disabled svg circle{ fill: var(--gray); }
.input-bar button:focus{ outline: 0; }
.input-bar button:focus svg circle{ fill: var(--purple); }

.button-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--purple);
    padding:0;
}
.button-link:hover {
    text-decoration: underline;
}